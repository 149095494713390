import {
  OrderParams,
  ShippingAddress,
  Address,
  DeliveryType,
  PersonalData,
} from 'src/services/api';

export type PaymentStatus = 'pending' | 'succeeded' | null;
export type PaymentMethod = 'Card' | 'Contract';

export enum ParcelDeliveryType {
  Sameday = 'SameDay',
  Nextday = 'NextDay',
}

export enum ParcelPickupType {
  'Asap' = 'Asap',
  'Scheduled' = 'Scheduled',
}

export interface CreateParcelModel extends OrderParams {
  // Package ID
  id: string;
  receiverData: PersonalData;
}

export interface UpdateParcelModel extends OrderParams {
  receiverData: PersonalData;
}

//TODO: Unify parcel models
export interface GetParcelModel {
  // Package ID
  id: string;
  externalId?: string,
  orderId: string;
  courierId?: string;
  creationDate: string;
  senderId: string;
  pickupType: keyof typeof ParcelPickupType;
  referenceNumber1?: string | null;
  referenceNumber2?: string | null;
  senderData?: PersonalData;
  receiverData: PersonalData;
  courierData?: PersonalData;
  trackingId: string;
  parcelLocation: ParcelLocation;
  status: ParcelStatus;
  cost: Cost;
  statusHistory: { [key: string]: keyof typeof ParcelStatus };
  expiries: Array<AssignmentData>;
  rejections: Array<AssignmentData>;
  shipped: boolean;
  deliveryEnd?: string | null;
  aggregationPointId?: string | null;
  note?: string | null;
  deliveryType: DeliveryType;
  //Package data
  size: {
    weightInKg: number;
    dimensions: {
      x: number;
      y: number;
      z: number;
    };
  };
  requiresAttention: boolean;
  containsAlcohol: boolean;

  //Payment data
  paymentMethod: PaymentMethod;
  paymentStatus: PaymentStatus;
}

export interface Parcel {
  // Package ID
  id: string;
  orderId: string;
  creationDate: string;
  senderId: string;
  pickupType: keyof typeof ParcelPickupType;
  referenceNumber1?: string | null;
  referenceNumber2?: string | null;
  senderData?: PersonalData;
  receiverData: PersonalData;
  courierData?: PersonalData;
  trackingId: string;
  parcelLocation: ParcelLocation;
  status: ParcelStatus;
  cost: Cost;
  statusHistory: ParcelStatusHistory;
  expiries: Array<AssignmentData>;
  rejections: Array<AssignmentData>;
  shipped: boolean;
  deliveryEnd?: string | null;
  paymentMethod: PaymentMethod;
  paymentStatus: PaymentStatus;
  aggregationPointId?: string | null;
  note?: string | null;
  parcels: Package[];
  dataHasToBeFixed: boolean;
  deliveryType: DeliveryType;
}

export interface DispatchingParcel extends GetParcelModel {
  courierParcels: GetParcelModel[];
}

export interface Package {
  id?: string;
  weight: number;
  dimension_x: number;
  dimension_y: number;
  dimension_z: number;
  requiresAttention: boolean;
}

export interface AssignmentData {
  courierId: string;
  timestamp: number;
}

export type ParcelStatusHistory = {
  [key: string]: keyof typeof ParcelStatus;
};

export enum ParcelStatus {
  Cancelled = 'Cancelled',
  Scheduled = 'Scheduled',
  FixRequired = 'FixRequired',
  NeedsLabel = 'NeedsLabel',
  ReadyToSubmit = 'ReadyToSubmit',
  ReadyForPickup = 'ReadyForPickup',
  DriverAssigned = 'DriverAssigned',
  OutForDelivery = 'OutForDelivery',
  Delivered = 'Delivered',
  UnsuccessfulAttempt = 'UnsuccessfulAttempt',
  ReturnedToSender = 'ReturnedToSender',
  ReturningToSender = 'ReturningToSender',
  RetryingDelivery = 'RetryingDelivery',
  InTransit = 'InTransit',
  InHub = 'InHub',
  Lost = 'Lost',
  Investigating = 'Investigating',
}

export interface ParcelLocation {
  pickupWarehouseId: string;
  pickupAddress: Address;
  shippingAddress: ShippingAddress;
}

export type Cost = {
  value: number;
  currency: Currency;
};

export enum Currency {
  AUD = 'AUD',
}

export function getStatusName(
  status: ParcelStatus,
  paymentStatus?: PaymentStatus,
  paymentMethod?: PaymentMethod,
  isTrackerStatus?: boolean
) {
  if (
    paymentMethod &&
    paymentMethod === 'Card' &&
    paymentStatus !== 'succeeded' &&
    status !== ParcelStatus.Cancelled &&
    status !== ParcelStatus.FixRequired
  )
    return 'Needs payment';

  if (isTrackerStatus && status === ParcelStatus.NeedsLabel)
    return 'Being packed';

  switch (status) {
    case ParcelStatus.Cancelled:
      return 'Cancelled';
    case ParcelStatus.Scheduled:
      return 'Scheduled';
    case ParcelStatus.FixRequired:
      return 'Need fix';
    case ParcelStatus.NeedsLabel:
      return 'Needs label';
    case ParcelStatus.ReadyToSubmit:
      return 'Ready to submit';
    case ParcelStatus.ReadyForPickup:
      return 'Ready for pick up';
    case ParcelStatus.DriverAssigned:
      return 'Driver assigned';
    case ParcelStatus.OutForDelivery:
      return 'Out for delivery';
    case ParcelStatus.Delivered:
      return 'Delivered';
    case ParcelStatus.UnsuccessfulAttempt:
      return 'Unsuccessful attempt';
    case ParcelStatus.ReturningToSender:
      return 'Returning to sender';
    case ParcelStatus.ReturnedToSender:
      return 'Returned to sender';
    case ParcelStatus.InTransit:
      return 'In transit';
    case ParcelStatus.InHub:
      return 'In hub';
    case ParcelStatus.Lost:
      return 'Lost';
    case ParcelStatus.RetryingDelivery:
      return 'Retrying delivery';
    default:
      return '';
  }
}

export function getStatusClassName(
  status: ParcelStatus,
  paymentStatus: PaymentStatus,
  paymentMethod: PaymentMethod
) {
  if (
    paymentMethod === 'Card' &&
    paymentStatus !== 'succeeded' &&
    status !== ParcelStatus.Cancelled &&
    status !== ParcelStatus.FixRequired
  )
    return 'dip_label-needs_payment';

  switch (status) {
    case ParcelStatus.FixRequired:
      return 'dip_label-needs_fix';
    case ParcelStatus.Cancelled:
      return 'dip_label-rejected';
    case ParcelStatus.NeedsLabel:
      return 'dip_label-draft';
    case ParcelStatus.ReadyForPickup:
      return 'dip_label-created';
    case ParcelStatus.DriverAssigned:
      return 'dip_label-assigned';
    case ParcelStatus.OutForDelivery:
      return 'dip_label-out_for_delivery';
    case ParcelStatus.Delivered:
      return 'dip_label-delivered';
    case ParcelStatus.ReturningToSender:
      return 'dip_label-out_for_delivery';
    case ParcelStatus.ReturnedToSender:
      return 'dip_label-delivered';
    case ParcelStatus.Lost:
      return 'dip_label-lost';
    default:
      return '';
  }
}

export function groupGetParcelModel<T>(
  value: GetParcelModel[],
  orderColumn: keyof GetParcelModel,
  idColumn: keyof GetParcelModel,
  groupItem: keyof T,
  groupConverter: (item: GetParcelModel) => Partial<GetParcelModel>
): T[] {
  const grouped = value.reduce((r: { [key: string]: GetParcelModel[] }, a) => {
    r[a[orderColumn] as any] = [...(r[a[orderColumn] as any] || []), a];
    return r;
  }, {});

  return Object.keys(grouped)
    .map((key: string) => {
      const array = grouped[key] as GetParcelModel[];
      if (key === 'undefined') {
        return array as any[];
      }

      return [
        {
          ...(array[0] as any),
          [groupItem]: array.map((a) => ({
            ...groupConverter(a),
            id: a[idColumn] as any,
          })),
        } as T,
      ];
    })
    .reduce((a: T[], b: T[]) => [...a, ...b], []);
}
